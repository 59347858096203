export function cartTotals(cart, transaction) {

  cart = { ...cart };

  let subTotal = 0;
  let total = 0;
  let totalPaid = 0
  let discounts = 0
  let delivery = 0
  let change = 0
  let tips = 0
  let totalRemaining = 0
  let tax = 0
  let refund = 0
  let serviceCharge = 0;

  // Filters out void or comp items from cart totals
  cart.items = cart.items.filter(i => i.voided?.isVoid !== true && i.comp?.isComp !== true);


  // Finds Sub Total
  cart.items.map(item => subTotal += item.variant ?
    item.damount ? item.dtype == "amount" ? item.variant.price * item.quantity - item.damount : (item.variant.price * item.quantity) - ((item.variant.price * item.quantity) / 100 * item.damount)
    : item.variant.price * item.quantity
    : item.damount ? item.dtype == "amount" ? item.price * item.quantity - item.damount : (item.price * item.quantity) - ((item.price * item.quantity) / 100 * item.damount)
    : item.price * item.quantity
  );

  // Looks at Item Modifiers and also adds to subTotal 
  cart.items.map(item => {
    if (typeof item.modifiers != "undefined" && item.modifiers.length > 0) {
      item.modifiers.map(modifier => {
        let modifierDiscount = 0;
        if (item.damount) {
          modifierDiscount = item.dtype == "amount" ? item.damount / item.modifiers.length : (modifier.price * item.quantity) * (item.damount / 100);
        }
        subTotal += (modifier.price * item.quantity) - modifierDiscount;
      });
    }
  });

  const originalSubTotal = subTotal; // Store the original subtotal before discounts

  // Calculate discounts
  let cartDiscount = 0;
  if (cart.discount?.type === "percentage") {
    cartDiscount = subTotal * (cart.discount.amount / 100);
  } else if (cart.discount?.type === "amount") {
    cartDiscount = cart.discount.amount;
  }

  discounts += cartDiscount;

    // Automatic Discounts
  // Loop through each applied discount and calculate the discount value
  cart.automaticDiscounts?.forEach(discount => {
    const eligibleItems = cart.items.filter(item => 
      (!discount.condition.item_ids || discount.condition.item_ids.length === 0 || discount.condition.item_ids.includes(item.pid)) &&
      (!discount.condition.category_ids || discount.condition.category_ids.length === 0 || discount.condition.category_ids.includes(item.category))
    );

    const eligibleItemCount = eligibleItems.reduce((sum, item) => sum + item.quantity, 0);
    const eligibleSubTotal = eligibleItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    if (discount.condition.type === 'item_count') {
      const numDiscounts = Math.floor(eligibleItemCount / discount.condition.min_items);
      
      // If applyOnce is true, apply the discount only once
      const applicableDiscounts = discount.condition.applyOnce ? Math.min(numDiscounts, 1) : numDiscounts;

      if (discount.type === 'percentage') {
        const discountSubtotal = (eligibleSubTotal / eligibleItemCount) * discount.condition.min_items;
        discounts += (discountSubtotal * (discount.amount / 100)) * applicableDiscounts;
      } else if (discount.type === 'amount') {
        discounts += discount.amount * applicableDiscounts;
      }
    } else {
      if (discount.type === 'percentage') {
        discounts += eligibleSubTotal * (discount.amount / 100);
      } else if (discount.type === 'amount') {
        discounts += discount.amount;
      }
    }
  });


  // Calculate tax after discounts (ensure VAT is not applied to £0 net amounts)
  if (subTotal > 0) {
    cart.items.map(item => {
      if (item.tax != undefined) {
        let price = item.variant ? item.variant.price : item.price;
        let quantity = item.quantity;
        let discountAmount = item.damount ? item.damount : 0;

        if (item.dtype == "amount") {
          discountAmount *= quantity;
        } else {
          discountAmount = (price * quantity) * (discountAmount / 100);
        }

        let taxableAmount = Math.max((price * quantity) - discountAmount - (cartDiscount * ((price * quantity) / (subTotal || 1))), 0);
        let itemTax = taxableAmount / (1 + item.tax.rate) * item.tax.rate;
        tax += itemTax;
      }
    });

    cart.items.map(item => {
      if (typeof item.modifiers != "undefined" && item.modifiers.length > 0) {
        item.modifiers.map(modifier => {
          if (modifier.tax != undefined) {
            let price = modifier.price;
            let quantity = item.quantity;

            let modifierTaxableAmount = Math.max(price * quantity - (cartDiscount * ((price * quantity) / (subTotal || 1))), 0);
            let modifierTax = modifierTaxableAmount / (1 + modifier.tax.rate) * modifier.tax.rate;
            tax += modifierTax;
          }
        });
      }
    });
  }

  // Adds Order delivery
  if (cart.customer?.location != undefined && Object.keys(cart.customer?.location).length > 0) {
    delivery = cart.customer?.location[0].price;
    total += delivery;
  }

  console.log(total)

  // Adds service charges and calculates tax for each service charge
  if (cart?.serviceCharges && Array.isArray(cart?.serviceCharges)) {
    cart.serviceCharges.map(charge => {
      if (charge.userRemoved) return;
  
      let chargeAmount = charge.amount;
      if (charge.type === "percentage") {
        chargeAmount = originalSubTotal * (charge.amount / 100); // Use original subtotal for service charge calculation
      }
  
      serviceCharge += chargeAmount;
      total += chargeAmount;
  
      if (charge.tax != undefined) {
        let price = chargeAmount;
        let chargeTaxableAmount = price / (1 + charge.tax.rate); // Extract the pre-VAT amount
        let chargeTax = price - chargeTaxableAmount; // Compute VAT as the difference
        tax += chargeTax; // Add VAT to the total tax
      }
    });
  }

  // Adds total paid
  cart.payments?.map(payment => totalPaid += payment.amount);

  // Adds Payments Change
  cart.payments?.map(item => change += item.change != undefined ? item.change : 0);

  // Adds Payments Tips
  cart.payments?.map(item => tips += item.tipAmount != undefined ? item.tipAmount : 0);

  // Calculate total
  total = originalSubTotal - discounts + serviceCharge + delivery + tips;
  totalPaid += tips;
  totalRemaining = Math.max(total - totalPaid, 0); // Ensure "To Pay" is never negative

  // Refund
  refund = cart?.refundValue || 0;

  // Rounds totals
  subTotal = Math.round((subTotal + Number.EPSILON) * 100) / 100;
  total = Math.round((total + Number.EPSILON) * 100) / 100;
  totalPaid = Math.round((totalPaid + Number.EPSILON) * 100) / 100;
  discounts = Math.round((discounts + Number.EPSILON) * 100) / 100;
  delivery = Math.round((delivery + Number.EPSILON) * 100) / 100;
  change = Math.round((change + Number.EPSILON) * 100) / 100;
  tips = Math.round((tips + Number.EPSILON) * 100) / 100;
  totalRemaining = Math.round((totalRemaining + Number.EPSILON) * 100) / 100;
  tax = Math.round((tax + Number.EPSILON) * 100) / 100;
  refund = Math.round((refund + Number.EPSILON) * 100) / 100;
  serviceCharge = Math.round((serviceCharge + Number.EPSILON) * 100) / 100;

  return { subTotal: originalSubTotal, total, totalPaid, discounts, delivery, change, tips, totalRemaining, tax, refund, serviceCharge };
}