import * as React from 'react';
import { useRef, createRef, Component } from 'react';
import { View, ScrollView, StyleSheet, FlatListProps, ActivityIndicator, PixelRatio, Text, Button, TouchableOpacity, TouchableHighlight, SectionList, FlatList, Alert as NativeAlert, Platform, Dimensions } from 'react-native';

import { Constants } from 'expo';

import sectionListGetItemLayout from 'react-native-section-list-get-item-layout'


import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';


import { SectionGrid } from 'react-native-super-grid';

const { width: WIDTH, height: HEIGHT } = Dimensions.get('window');

import * as firebase from 'firebase';
import 'firebase/firestore';

import { connect, useDispatch } from 'react-redux';
import { watchBranchesData, watchBranchesHoursData } from '../api/branchesData';
import { selectBranch } from '../api/branches';

import BranchesScreen from './BranchesScreen'

import { setItemData, watchItemData, setItemVariantData, watchItemVariantData, setDepartmentData, watchDepartmentData, setCategoryData, watchCategoryData, setModifierData, watchModifierData, setModifierItemData, watchModifierItemData } from './../api/itemData';

import { setDeliveriesData, watchDeliveriesData } from './../api/deliveriesData'
import { setUsersData, watchUsersData } from './../api/usersData'
import { setDiscountsData, watchDiscountsData } from '../api/discountsData'


import { changeBranch } from '../api/branches';
import { resetCart } from './../api/orders'

import OrderItem from './../components/OrderItem'
import CartModal from './../components/CartModal'
import ItemAddModal from './../components/ItemAddModal'
import OrderReceivedModal from './../components/OrderReceivedModal'

import CategoryItem from './../containers/CategoryItem'
import CategoryName from './../containers/CategoryName'

import { colors } from '../constants/colors';
import { fonts } from  '../constants/fonts';

const resetItemData = () => {
  return {
    type: "RESET_ITEM_DATA",
  }
}


const mapStateToProps = (state) => {
  return {
    modifierData: state.itemData.modifierData,
    modifierItemData: state.itemData.modifierItemData,
    usersData: state.usersData,
    branchesData: state.branchesData,
    branchesHoursData: state.branchesData.hoursData,
    deliveriesData: state.deliveriesData,
    itemData: state.itemData.itemData,
    itemVariantData: state.itemData.itemVariantData,
    departmentData: state.itemData.departmentData,
    categoryData: state.itemData.categoryData,
    itemDataIsLoading: state.itemData.isLoading,
    cartData: state.cartData
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersData: () => dispatch(watchUsersData()),
    watchBranchesData: () => dispatch(watchBranchesData()),
    watchBranchesHoursData: () => dispatch(watchBranchesHoursData()),
    watchDeliveriesData: () => dispatch(watchDeliveriesData()),
    watchDepartmentData: () => dispatch(watchDepartmentData()),
    watchCategoryData: () => dispatch(watchCategoryData()),
    watchItemData: () => dispatch(watchItemData()),
    watchItemVariantData: () => dispatch(watchItemVariantData()),
    watchModifierData: () => dispatch(watchModifierData()),
    watchModifierItemData: () => dispatch(watchModifierItemData()),
    watchDiscountsData: () => dispatch(watchDiscountsData()),
    addItemToCart: (item, index, variant, modifiers, note, quantity) => dispatch({ type: 'ADD_TO_CART', item: item, variant: variant, modifiers: modifiers, note: note, quantity: quantity }),
    removeItem: (product) => dispatch({type: 'REMOVE_FROM_CART', payload: product}),
    addOrderNote: (note) => dispatch({type: 'ADD_ORDER_NOTE', payload: note}),
    addOrderDiscount: (discount) => dispatch({type: 'ADD_ORDER_DISCOUNT', payload: discount}),
    setOrderType: (type) => dispatch({type: 'UPDATE_ORDER_TYPE', payload: type}),
    removeOrderType: (type) => dispatch({type: 'REMOVE_ORDER_TYPE', payload: type}),
    setPaymentType: (type) => dispatch({type: 'UPDATE_PAYMENT_TYPE', payload: type}),
    removePaymentType: (type) => dispatch({type: 'REMOVE_PAYMENT_TYPE', payload: type}),
  };
}


function ShowBasketButton({ onPress, cartData }) {

  let cartTotal = 0
  let cartQuantity = 0

  cartData.map(item => cartTotal += item.variant ?
    item.damount ? item.dtype == "amount" ? item.variant.price * item.quantity - item.damount : (item.variant.price * item.quantity) - ((item.variant.price * item.quantity) / 100 * item.damount)
    : item.variant.price * item.quantity
    : item.damount ? item.dtype == "amount" ? item.price * item.quantity - item.damount : (item.price * item.quantity) - ((item.price * item.quantity) / 100 * item.damount)
    : item.price * item.quantity
  );

  cartData.map(item => cartQuantity += item.quantity)

  return (
      <TouchableOpacity style={{ width: "100%", position: "sticky", bottom: 0, left: 0, right: 0, backgroundColor: colors.secondary, height: 80, elevation: 8, }} onPress={onPress}>
        <SafeAreaView forceInset={{ bottom: 'always' }} style={{flexDirection: "row", paddingHorizontal: 30, paddingVertical: Platform.OS == "web" ? 30 : 0, marginVertical: 0}}>
          <Text style={{ fontSize: 13, color: colors.primary, fontFamily: fonts.bold, backgroundColor: 'rgba(10, 10, 10, 0.1)', paddingVertical: 4 , paddingHorizontal: 8, marginTop: -3, marginRight: 10}} adjustsFontSizeToFit allowFontScaling>{cartQuantity}</Text>
          <Text style={{ flex: 2, fontSize: 15, color: colors.primary, fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>View Order</Text>
          <Text style={{ flex: 1, fontSize: 15, color: colors.primary, textAlign: "right", fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>£{(+cartTotal).toFixed(2)}</Text>
        </SafeAreaView>
      </TouchableOpacity>
  )
}

function ChangeBranch({ cartTotal, branch, noBranches }) {
  const dispatch = useDispatch()

  const reset = () => {
    dispatch(changeBranch()),
    dispatch(resetCart()),
    dispatch(resetItemData())
    global.branchId = null
  }

  const userAlert = () => {
    if (Platform.OS == "web") {
      if(window.confirm("Are you sure? Your order will be lost.")) {
        dispatch(changeBranch()),
        dispatch(resetCart())
        global.branchId = null
      }
    } else {
    NativeAlert.alert(
      "Are you sure?",
      "Your order will be lost",
      [
        {
          text: "Cancel",
          onPress: null,
          style: "cancel"
        },
        { text: "Yes", onPress: reset }
      ],
      { cancelable: false }
    );
    }
  }

return (
  <View style={{ flex: 1,flexDirection: "row", paddingTop: 10, paddingBottom: 0}}>
    <Text style={{color: colors.white, fontSize: 13, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>{branch.name} {branch.location}</Text>
    {noBranches > 1 ?
    <TouchableOpacity
      onPress={cartTotal > 0 ? userAlert : reset}
    >
      <Text style={{color: "#eeeeee", paddingHorizontal: 5, fontSize: 13, fontFamily: fonts.regular}} adjustsFontSizeToFit allowFontScaling>Change</Text>
    </TouchableOpacity>
    : null}
  </View>
)
}


function GroupByCategory(items) {
  const result = [...items.reduce((r, { category, id, name, price, description }) => {
    r.has(category) || r.set(category, {
      category,
      data: []
    });

    r.get(category).data.push({ id, name, category, price, description });

    return r;
  }, new Map).values()];

  return result
}


function GroupByCategoryNew(categories, items) {

  // Finds all aviable categories and pushes items into them.
  var result = []

  categories.map(c => {
  var formatted_items = []

   var citems = [...items].filter(i => i.category === c.id).map(i => {formatted_items.push({
     id: i.id,
     title: i.name,
     name: i.name,
     price: i.price,
     description: i.description,
     category: i.category,
     isAvailable: i.isAvailable,
     image: i.image,
     allergens: i.allergens,
     calories: i.calories
   })})

   result.push({
     title: c.name,
     id: "c" + c.id,
     data: formatted_items,
     description: c.description,
   })
  })

  return result
}

const ITEM_HEIGHT = 125;

class OrderScreen extends Component {

  constructor(props) {
      super(props);
      this.props.watchUsersData();
      this.props.watchBranchesData()
      this.props.watchBranchesHoursData()
      this.props.watchDepartmentData();
      this.props.watchCategoryData();
      this.props.watchModifierData();
      this.props.watchModifierItemData();
      this.props.watchItemData();
      this.props.watchItemVariantData();
      this.props.watchDeliveriesData();
      this.props.watchDiscountsData()

      this.state = {
        data: null,
        isCartModalVisible: false,
        isItemAddVisible: false,
        isOrderReceivedVisible: false,
        orderReceived: null,
        itemSelected: [],
        itemSelectedIndex: [],
        itemSelectedVariants: [],
        itemSelectedModifiers: [],
      }

           this.itemHeights = [];
  }

  toggleCartModal = () => {
    this.setState(state => ({
      isCartModalVisible: !state.isCartModalVisible
    }))
  }

  toggleOrderReceivedModal = (transaction) => {

    if(transaction == null) {
      this.setState(state => ({
        isOrderReceivedVisible: !state.isOrderReceivedVisible,
        orderReceived: null,
      }));
    } else {
      this.setState(state => ({
        isOrderReceivedVisible: !state.isOrderReceivedVisible,
        orderReceived: transaction,
      }));
    }
  }

  openItemModal = (item, index, itemVariant, itemModifiers) => {
    this.setState(state => ({
      isItemAddVisible: !state.isItemAddVisible,
      itemSelected: item,
      itemselectedIndex: index,
      itemSelectedVariants: itemVariant,
      itemSelectedModifiers: itemModifiers,
    }));
  }

  closeItemModal = () => {
    this.setState(state => ({
      isItemAddVisible: !state.isItemAddVisible,
      itemSelected: [],
      itemSelectedIndex: [],
      itemSelectedVariants: [],
      itemSelectedModifiers: [],
    }));
  }

  scrollToSection = (index) => {
    this.sectionListRef.scrollToLocation({
      animated: true,
      sectionIndex: index,
      itemIndex: 0,
      offset: 0,
      viewPosition: 0
    });
  };

  renderSectionHeader = ({ section }) => {
    return (
      <CategoryName
        id={section.id}
        title={section.title}
        description={section.description}
      />
    )
  }

  getItemLayout = sectionListGetItemLayout({
    // The height of the row with rowData at the given sectionIndex and rowIndex
    getItemHeight: (rowData, sectionIndex, rowIndex) => global.windowWidth > 768 ? 134 : 127,

    // These four properties are optional
    getSeparatorHeight: () => global.windowWidth > 768 ? 20 : 0, // The height of your separators
    getSectionHeaderHeight: () => 86, // The height of your section headers
    getSectionFooterHeight: () => 20, // The height of your section footers
    listHeaderHeight: 0, // The height of your list header
  })

  renderItem = ({item, section, index}) => {
    return (
    <OrderItem
      item={item}
      variants={this.props.itemVariantData.filter(v => v.item === item.id)}
      index={section + index}
      openItemModal={() => this.openItemModal(item)}
      closeItemModal={() => this.closeItemModal()}
    />
  )
};

  render() {

    let categoryData = []
    let branchesHoursData = []

    if(this.props.departmentData.length > 0) {
      categoryData = this.props.categoryData.filter(category => category.department === this.props.departmentData[0].id)
    }

    if (this.props.branchesHoursData.length > 0) {
      branchesHoursData = [...this.props.branchesHoursData].filter(branch => branch.branch === global.branchId)
    }

    const itemData = GroupByCategoryNew(categoryData, this.props.itemData)

    var currentDate = new Date();
    var currentDay = currentDate.getDay()
    var currentHour = currentDate.getHours()
    var currentMinute = currentDate.getMinutes()

    var currentTime = [currentHour,currentMinute].join('')

    const today = branchesHoursData.filter(hours => hours.day == currentDay && hours.opening <= currentTime && hours.closing >= currentTime)

    var open = "Closed"
    if (today.length > 0) { open = "Open"}


    if (this.props.itemDataIsLoading == true) {
      return (
        <View style={styles.main}>

          <View style={styles.branch}>
            <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
            <Text style={{color: colors.secondary, fontSize: 24, fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>Order Online</Text>

            <ChangeBranch
              branch={this.props.branchesData.selectedBranch}
              noBranches={this.props.branchesData.branchesData.length}
              cartTotal={this.props.cartData.items.length}
            />
            </View>
          </View>

          <View style={styles.categories}>
            <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
            </View>
          </View>

          <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center"}}>
              <ActivityIndicator size="small" color={colors.primary} />
            </View>
          </View>

        </View>
      )
    } else {
    return (
      <View style={styles.main}>
        <View style={styles.branch}>
          <View style={[styles.container, {flexDirection: "row", paddingHorizontal: global.windowWidth > 768 ? 30 : 0 } ]}>
          <View style={{ flex: 4}}>
          <Text style={{color: colors.secondary, fontSize: 24, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Order Online</Text>

          <ChangeBranch
            branch={this.props.branchesData.selectedBranch}
            noBranches={this.props.branchesData.branchesData.length}
            cartTotal={this.props.cartData.items.length}
          />
          </View>

          {this.props.branchesData.branchesData.length == 1 ?
          <View style={{ flex: 1.5, paddingTop: 15, alignItems: "flex-end"}}>

            {open == "Open" ?
            <View style={{ padding: 5, backgroundColor: colors.green, width: 90,  borderRadius: 2}}>
              <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold,}} adjustsFontSizeToFit allowFontScaling>Open</Text>
            </View>
            : open == "Closed" && this.props.branchesData.selectedBranch.isPreOrder == true ?
            <View style={{ padding: 5, backgroundColor: colors.yellow, width: 90,  borderRadius: 2}}>
              <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Pre Order</Text>
            </View>
            :         <View style={{ padding: 5, backgroundColor: colors.red, width: 90,  borderRadius: 2}}>
                      <Text style={{fontSize: 13, textAlign:"center", color: colors.white, fontFamily: fonts.bold}} adjustsFontSizeToFit allowFontScaling>Closed</Text>
                    </View>
                  }

            {today.length > 0 ?
              <Text style={{ marginTop: 6, fontSize: 12, color: colors.white, fontFamily: fonts.regular }}>{today[0].opening.replace(/(..)/g, '$1:').slice(0,-1)}-{today[0].closing.replace(/(..)/g, '$1:').slice(0,-1)}</Text>
            : null}
          </View>
          : null }
          </View>
        </View>

        <View style={[styles.categories, {position: "sticky", top: 0, left: 0, elevation: 8, zIndex: 10} ]}>
          <View style={[styles.container, {paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>
          <ScrollView style={{}} horizontal={true} >
            {itemData.map((section, index) => {
              return (
                <CategoryItem
                  title={section.title}
                  id={section.id}
                  key={index}
                  onPress={() => this.scrollToSection(index)}
                />
              )
            })}
          </ScrollView>
          </View>
        </View>

        <View style={[styles.container, { paddingHorizontal: global.windowWidth > 768 ? 30 : 0} ]}>

        <View style={styles.body}>
        <View style={ global.windowWidth > 768 ? styles.itemsWeb : styles.items }>
        <View style={{ flex: 1}}>
        {itemData != null ?
          <SectionGrid
            style={[styles.sectionList]}
            sections={itemData}
            itemDimension={global.windowWidth > 768 ? 300 : 300 }
            spacing={global.windowWidth > 768 ? 20 : 0}
            listKey={"1"}
            keyExtractor={(item, index) => String(item.title + index)}
            ref={ref => (this.sectionListRef = ref)}
            renderSectionHeader={this.renderSectionHeader}
            renderItem={(item, section, index) => this.renderItem(item, section, index)}
            getItemLayout={this.getItemLayout}
            stickySectionHeadersEnabled={Platform.OS == "web" ? false : true}
            removeClippedSubviews={true}
            disableVirtualization={true}
            initialNumToRender={40}
            legacyImplementation={true}
            ListFooterComponent={() =>
              <View style={styles.allergyNotes}>
                <Text style={styles.allergyNotesText} adjustsFontSizeToFit allowFontScaling>{this.props.branchesData.selectedBranch.allergyNotes.split("<br />").join("\n \n")}</Text>
              </View>
            }
          />
          : null}
          </View>
        </View>

        <View style={[styles.cart, { flex: global.windowWidth > 768 ? 0.5 : 0}]}>
          <CartModal
            cartData={this.props.cartData}
            usersData={this.props.usersData}
            branchData={this.props.branchesData.selectedBranch}
            hoursData={this.props.branchesData.hoursData}
            deliveriesData={this.props.deliveriesData.deliveriesData}
            modalSwap={() => this.modalSwap()}
            toggleCartModal={() => this.toggleCartModal()}
            toggleCheckoutModal={() => this.toggleCheckoutModal()}
            toggleOrderReceivedModal={(transaction) => this.toggleOrderReceivedModal(transaction)}
            removeItem={(item, index) => this.props.removeItem(item, index)}
            addOrderNote={note => this.props.addOrderNote(note)}
            addOrderDiscount={discount => this.props.addOrderDiscount(discount)}
            isCartVisible={this.state.isCartModalVisible}
            isOrderReceivedVisible={this.state.isOrderReceivedVisible}
            isCheckoutVisible={this.state.isCheckoutModalVisible}
            setOrderType={(type) => this.props.setOrderType(type)}
            setPaymentType={(type) => this.props.setPaymentType(type)}
          />

        </View>
        </View>

        <ItemAddModal
          item={this.state.itemSelected}
          index={this.state.itemSelectedIndex}
          isVisible={this.state.isItemAddVisible}
          onSubmit={(item, index, itemVariant, itemModifiers, itemNote, itemQuantity) => this.props.addItemToCart(item, index, itemVariant, itemModifiers, itemNote, itemQuantity)}
          variantData={this.props.itemVariantData}
          variants={this.state.itemSelectedVariants}
          modifiers={this.props.modifierData}
          modifierItems={this.props.modifierItemData}
          closeItemModal={this.closeItemModal}
        />

        <OrderReceivedModal
          orderDetails={this.state.orderReceived}
          isVisible={this.state.isOrderReceivedVisible}
          toggleOrderReceivedModal={(order) => this.toggleOrderReceivedModal(order)}
        />
      </View>

      {global.windowWidth < 768 && this.props.cartData.items.length > 0 ?
      <ShowBasketButton
        cartData={this.props.cartData.items}
        onPress={this.toggleCartModal}
      />
      : null }

      </View>
    )
  }
}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen);

const styles = StyleSheet.create({
  main: {
    flexGrow: 1,
    backgroundColor: colors.white,
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
  },
  text: {
    fontFamily: fonts.regular,
    color: colors.text,
    padding: 20,
  },
  branch: {
    height: 105,
    paddingTop: 20,
    paddingBottom: 0,
    paddingHorizontal: 30,
    backgroundColor: colors.primary,
  },
  categories: {
    height: 84,
    marginTop: -25,
    backgroundColor: colors.primary,
    fontFamily: fonts.bold
  },
  body: {
    flexDirection: "row",
    flex: 1,
  },
  items: {
    flex: 3,
  },
  itemsWeb: {
    flex: 1,
    marginLeft: -30,
    paddingLeft: -30,
    paddingTop: 20,
  },
  itemsHeader: {
    fontSize: 22,
    color: colors.text,
    paddingTop: 35,
    paddingBottom: 15,
    height: 76,
    fontFamily: fonts.bold,
    paddingHorizontal: global.windowWidth > 768 ? 0 : 30,
  },
  cart: {
    flex: 0.5,
  },
  allergyNotes: {
    flex: 1,
    paddingHorizontal: 30,
    paddingVertical: 20,
    paddingBottom: 120,
    fontFamily: fonts.regular,
  },
  allergyNotesText: {
    color: colors.text,
    fontSize: 12,
  },
  header: {
    height: ITEM_HEIGHT,
  },
  item: {
    height: ITEM_HEIGHT,
  },
  sectionList: {
  }
});
